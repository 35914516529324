.main_container {
  /* margin-left: 3rem; */
  margin-top: 5rem;
}

.dateTimeInput {
}

.dateTimeInputWrapper {
  position: relative;
  width: 100% !important;
}

/* Style the calendar dropdown */
.react-datepicker {
  position: absolute;
  z-index: 9999; /* Ensure it appears on top of other elements */
}

/* Adjust the position of the calendar dropdown for small screens */
@media (max-width: 576px) {
  .react-datepicker {
    top: calc(100% + 10px); /* Adjust as needed */
    left: 50%; /* Position it horizontally centered */
    transform: translateX(-50%); /* Center it horizontally */
  }
}

.alert-bg-colour {
  background-color: #d4edda;
}

.jumbotron {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.card-header-colour {
  background-color: #527d31;
}

.custom_button {
  display: inline-block;
  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #527d31;
  color: #212529;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  margin-top: 2rem;
  width: 29.5rem;
  transition: background-color 0.3s ease;
}

.custom_button:hover {
  background-color: #d4f5bb;
}
