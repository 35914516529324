/* body{
    background-image: url('../public/undraw_things_to_say_re_jpcg.svg');
    background-size: 63% auto;
    background-repeat: no-repeat;
    background-position: calc(160% - 8%) 84%;
    background-color: rgba(255, 255, 255, 0.267); 
    background-blend-mode: lighten;
} */

.nav-link {
  padding: 2px;
  @media screen and (max-width: 340px) {
    font-size: 0.8rem;
  }
}

.footer-about {
  font-size: 0.9rem;
  text-align: justify;
}
