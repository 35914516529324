.editTag {
  margin-left: 20rem;
}

.v_background {
  position: relative;
  overflow: hidden;
}
.v_background::before {
  content: "";
  position: absolute;
  top: 15%;
  left: -1%;
  width: 100%;
  height: 100%;
  background-image: url("../../../public/undraw_traveling_yhxq.svg");
  background-size: 50% auto;
  background-repeat: no-repeat;
  background-position: right top; /* Adjust as needed */
  background-color: rgba(255, 255, 255, 0.267);
  background-blend-mode: lighten;
  opacity: 0.2; /* Adjust this value to control opacity */
  z-index: -1; /* Ensure this is behind your content */
}

.custom-card:hover {
  background-color: #dff5ea;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3); /* Add shadow on hover */
}

.v_card {
  margin-top: 1.5rem;
}

.btn-add {
  margin-left: 2rem;
}

/* Custom styles for the dropdown */
.custom-dropdown-toggle::after {
  display: none !important; /* Remove default down arrow */
}

.custom-dropdown-menu {
  z-index: 9999 !important; /* Adjust z-index as needed */
}

.custom-input {
  width: 100% !important;
}
